import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
const axios = require('axios');

const routes = [
    {
        path: '/',
        name: 'home',
        alias: '/home',
        meta: {
            requiresAuth: true
        },
        component: HomeView
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
    },
    {
        path: '/signup',
        name: 'signup',
        alias: '/register',
        component: () => import(/* webpackChunkName: "about" */ '../views/SignupView.vue')
    },
    {
        path: '/food',
        name: 'food',
        meta: {
            requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/FoodView.vue')
    },
    {
        path: '/fridge',
        name: 'fridge',
        meta: {
            requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/FridgeView.vue')
    },
    {
        path: '/discount',
        name: 'discount',
        meta: {
            requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/DiscountView.vue')
    },
    {
        path: '/message',
        name: 'message',
        meta: {
            requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/MessageView.vue')
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        meta: {
            requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/DashboardView.vue')
    },
    {
        path: '/account',
        name: 'account',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/AccountView.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        component: () => import(/* webpackChunkName: "about" */ '../views/NotFoundView.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (!to.matched.some(record => record.meta.requiresAuth)) {
        next(); // does not require auth, make sure to always call next()!
        return;
    }

    // Ask the server if the given token is valid
    axios.get('/api/auth', {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('jwt')}`
        }
    }).then((res) => {
        next();
    }).catch(() => {
        next({ name: 'login', query: { redirect: to.name } });
    });
})

export default router
