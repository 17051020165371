<template>
  <div class="container-fluid">
    <div class="bordertop full row">
      <SideBar />

      <div class="main col-sm-9">
        <Timestamp />
        <div class="row">
          <div class="col-sm-8">
            <div class="col-sm-12 welcome align-self-center">
              <div class="row">
                <div class="col-sm-9">
                  <span class="welcometext">Welcome {{ name }},</span><br>Here you can manage everything related to your
                  Foodm account.<br>You can use the quick actions below
                  or head to a specific page to update your portal
                </div>
                <div class="col-sm-3"><img class="profilepic" src="../assets/man.png"></div>
              </div>
            </div>
          </div>
          <div class="col-sm-1 paddingclass">&nbsp;</div>
          <div class="col-sm-3 news">
            <span class="newstext">Contact</span>
            <ul>
              <a href="mailto:info@foodm.be">Foodm Contact</a><br>
            </ul>
            <span class="newstext">Download our apps</span>
            <ul>
              <a download :href=gustoScanApk>Download Gusto scan APK</a><br>
              <a download :href=deliveryApk>Download Delivery APK</a>
            </ul>
          </div>
        </div>
        <table class="bottomhome">
          <tr class="bottomrowtab">
            <td class="qabuttons">
              <div class="row">
                <span class="qatitle" style="text-align:left">Quick Actions</span>
                <div class="col-sm-4">
                  <router-link :to="{ name: 'food' }">
                    <button id="qabutton" class="left btn btn-primary" type="button"><img class="iconqa"
                        src="../assets/Icons/food-dinner.svg" alt="Icon"><br>New
                      Product<br><span class="extrainfo">Click to add a product</span></button>
                  </router-link>
                </div>
                <div class="col-sm-4">
                  <router-link :to="{ name: 'fridge' }">
                    <button id="qabutton" class="left btn btn-primary" type="button"><img class="iconqa"
                        src="../assets/Icons/fridge.svg"><br>New
                      Fridge<br><span class="extrainfo">Click to add a fridge</span></button>
                  </router-link>
                </div>
                <div class="col-sm-4">
                  <router-link :to="{ name: 'discount' }">
                    <button id="qabutton" class="left btn btn-primary" type="button"><img class="iconqa"
                        src="../assets/Icons/discount.svg" alt="Icon"><br>Discounts<br><span class="extrainfo">Click to
                        add
                        discounts</span></button>
                  </router-link>
                </div>
              </div>
            </td>
            <td rowspan="2" style="height: inherit;">
              <div class="row" style="height: 100%;">
                <div class="col-sm-1 paddingclass">&nbsp;</div>
                <div class="col-sm-11 insights">
                  <span class="newstext">Insights</span>

                  <div class="row">
                    <div class="col-sm-5 insightgreen">#{{ kpi.salesLast7Days }}<br><span class="extrainfo">Sales last 7 days</span></div>
                    <div class="col-sm-1">&nbsp;</div>
                    <div class="col-sm-5 insightgreen">#{{ kpi.totalSales }}<br><span class="extrainfo">Total sales</span></div>
                    <div class="col-sm-5 insightgreen">€{{ kpi.revenueLast7Days }}<br><span class="extrainfo">Revenue last 7 days</span></div>
                    <div class="col-sm-1">&nbsp;</div>
                    <div class="col-sm-5 insightgreen">€{{ kpi.totalRevenue }}<br><span class="extrainfo">Total revenue</span></div>
                  </div>
                  <router-link :to="{ name: 'dashboard' }">
                    <button id="dashboard" class="left btn btn-primary" type="button">Click to learn more
                    </button>
                  </router-link>
                </div>
              </div>
            </td>
          </tr>
          <tr class="bottomrowtab">
            <td style="height:450px">
              <div class="col-sm-12 dashboardhome" style="height:450px">
                <highcharts :options="revenueChartOptions"></highcharts>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import SideBar from '@/components/SideBar.vue'
import Timestamp from '@/components/Timestamp.vue'
import { Chart } from 'highcharts-vue'
import { GUSTO_SCAN_APK, DELIVERY_APK } from '../config/downloads';

export default {
  components: {
    SideBar,
    Timestamp,
    highcharts: Chart,
  },
  data() {
    return {
      gustoScanApk: GUSTO_SCAN_APK,
      deliveryApk: DELIVERY_APK,
      name: localStorage.getItem('name'),
      kpi: {
        totalRevenue: "",
        totalSales: "",
        revenueLast7Days: "",
        salesLast7Days: "",
      },
      revenueChartOptions: {
        series: [{
          name: "Revenue",
          data: [],
          color: "#00D89C"
        }],
        // chart: {
        //   height: 200,
        // },
        xAxis: {
          type: "datetime",
          // dateTimeLabelFormats: {
          //   day: '%d %b %Y'
          // },
        },
        yAxis: {
          title: ''
        },
        title: {
          text: "Revenue (last 6 months)"
        },
        chart: {
          zoomType: 'xy'
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
      },
    }
  },
  methods: {
    getKpi() {
      const headers = {
        "Authorization": `Bearer ${localStorage.getItem('jwt')}`
      };
      this.$http.post('/api/info/kpi/', null, { headers })
        .then((res) => {
          this.kpi = res.data;
        })
        .catch((error) => {
          console.error(error);
        });

      this.$http.post('/api/statistic/revenue/', null, { headers })
        .then((res) => {
          this.revenueChartOptions.series[0].data = res.data.result;
        })
        .catch((error) => {
          console.error(error);
        })
    },
  },
  created() {
    this.getKpi();
  }

};
</script>
