<template>
  <div class="nav col-sm-3">
    <router-link :to="{ name: 'home' }"><img class="logo" src="../assets/logo.svg" alt="logo"></router-link>
    <div class="d-grid gap-2 col-10 mx-auto">
      <router-link :to="{ name: 'home' }">
        <button id="navbutton" class="btn btn-outline-primary" type="button"><img class="icon"
            src="../assets/Icons/home.svg" alt="Icon">Home
        </button>
      </router-link>
      <router-link :to="{ name: 'food' }">
        <button id="navbutton" class="btn btn-outline-primary" type="button"><img class="icon"
            src="../assets/Icons/food-dinner.svg" alt="Icon">Products
        </button>
      </router-link>
      <router-link :to="{ name: 'fridge' }">
        <button id="navbutton" class="btn btn-outline-primary" type="button"><img class="icon"
            src="../assets/Icons/fridge.svg" alt="Icon">Fridges
        </button>
      </router-link>
      <router-link :to="{ name: 'discount' }">
        <button id="navbutton" class="btn btn-outline-primary" type="button"><img class="icon"
            src="../assets/Icons/discount.svg" alt="Icon">Discounts
        </button>
      </router-link>
      <router-link :to="{ name: 'message' }">
        <button id="navbutton" class="btn btn-outline-primary" type="button"><img class="icon"
            src="../assets/Icons/messages.svg" alt="Icon">Messages
        </button>
      </router-link>
      <router-link :to="{ name: 'dashboard' }">
        <button id="navbutton" class="btn btn-outline-primary" type="button"><img class="icon"
            src="../assets/Icons/analytics.svg" alt="Icon">Dashboard
        </button>
      </router-link>

      <button class="btn btn-link" type="button" @click="logout">Logout</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  methods: {
    logout() {
      const headers = {
        "Authorization": `Bearer ${localStorage.getItem('jwt')}`,
        "Content-Type": 'multipart/form-data'
      };

      axios.delete('/api/auth/logout/', { headers })
        .then((res) => {
          localStorage.removeItem('jwt');
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });

      this.$router.push('/login')
    },
  }
};
</script>
