<template>
  <div class="timestamp">{{ todaysDate }}</div>
</template>

<script>
export default {
  data() {
    return {
      todaysDate: new Date().toLocaleString('en-US', {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      })
    }
  },
}
</script>
