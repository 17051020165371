import {createApp} from "vue";
import Vue from "./App.vue";
import BootstrapVue3 from "bootstrap-vue-3";
import router from './router';

import {dom, library} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {fas} from '@fortawesome/free-solid-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';

import './assets/FoodM.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";

import axios from 'axios';

library.add(fas);
library.add(fab);
library.add(far);
dom.watch();

const app = createApp(Vue);

// register globally
app.component('font-awesome-icon', FontAwesomeIcon);

// createApp(App).use(router).use(BootstrapVue).mount('#app');
app.use(BootstrapVue3);
app.use(router).mount('#app');

// Configure the root path of the request 
// axios.defaults.baseURL = 'http://localhost:3000/'
// take axios Mount as app Global custom properties for （ Such as $http ）
app.config.globalProperties.$http = axios
