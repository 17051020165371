<template>
  <router-view />
</template>

<script>
export default {
  name: "app",
  data: function () {
    return {
      authenticated: false,
      messages: {},
      logged_user_id: null,
      logged_user_username: null,
    };
  },

  methods: {
    // Update the states
    async setAuthenticated(login_status, user_data) {
      this.logged_user_id = user_data.id;
      this.logged_user_username = user_data.username;
      this.authenticated = login_status;
      this.token = user_data.token;
    },
  },
};
</script>
